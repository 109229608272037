import { createContext, useContext, useEffect, useReducer } from "react";
import firstLoadersReducer from "./FirstLoadersReducer";
import TmdbContext from "../TmdbContext";
const FirstLoaderContext = createContext();
const TMDB_KEY = process.env.REACT_APP_TMDB_KEY;
const URL = "https://api.themoviedb.org/3/";
const lang = "&language=en-US&page=1";

export const FirstLoaderProvider = ({ children }) => {
  const initialState = {
    movies: [],
    series: [],
    topSeries: [],
    topMovies: [],
    latestMovies: [],
    bestLast20: [],
    best: [],
    upcoming: [],
    upcomingSeries: [],
    trendingMovies: [],
    trendingShows: [],
    trendingPeople: [],
  };
  const [state, dispatch] = useReducer(firstLoadersReducer, initialState);
  const { fetchComingSoon } = useContext(TmdbContext);
  const params = new URLSearchParams({
    api_key: TMDB_KEY,
  });

  function encode(data) {
    const encoder = new TextEncoder();
    const jsonData = JSON.stringify(data);
    const utf8Data = encoder.encode(jsonData);
    return btoa(String.fromCharCode(...utf8Data));
  }

  function decode(data) {
    const binaryString = atob(data);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    const decoder = new TextDecoder("utf-8");
    return JSON.parse(decoder.decode(bytes));
  }
  // get popular Movies and Shows
  const getPopularAndUpcoming = async () => {
    const twoDaysInMillis = 2 * 24 * 60 * 60 * 1000; // milliseconds in 2 days
    const now = new Date().getTime();

    // Attempt to retrieve stored data
    const storedData = localStorage.getItem("popularAndUpcomingData");
    let data = storedData ? JSON.parse(storedData) : null;

    // Check if the stored data is stale or not present
    const isDataStale = !data || now - data.timestamp > twoDaysInMillis;
    const networkId = [
      { networkId: 213, networkMovieId: 8 },
      { networkId: 1024, networkMovieId: 9 },
      { networkId: 2552, networkMovieId: 350 },
      { networkId: 591, networkMovieId: 591 },
      { networkId: 2739, networkMovieId: 337 },
    ];
    let upcomingSeriesTemp = [];
    for (let i = 0; i < networkId.length; i++) {
      const seriesData = await fetchComingSoon(networkId[i]);
      upcomingSeriesTemp.push(...seriesData.results);
    }

    const upcomingSeriesFiltered = upcomingSeriesTemp.filter(
      (series) => series.poster_path !== null
    );
    const upcomingSeries = upcomingSeriesFiltered
      .sort((a, b) => b.popularity - a.popularity)
      .slice(0, 20);

    if (isDataStale || !isDataStale) {
      // Data is stale or not present, fetch new data
      const currentYear = new Date().toISOString().split("T")[0];
      const [
        popularMovieResponse,
        popularTvResponse,
        upcomingMoviesAll,
        upcomingMoviesAll2,
        upcomingMoviesAll3,
      ] = await Promise.all([
        fetch(`${URL}movie/popular?${params}${lang}`),
        fetch(
          `${URL}discover/tv?${params}&language=en-US&sort_by=popularity.desc&first_air_date.gte=2018-01-01&first_air_date.lte=${currentYear}&page=1&timezone=America%2FNew_York&vote_average.gte=5&vote_count.gte=10&include_null_first_air_dates=false&with_watch_monetization_types=flatrate&with_status=0&with_type=0`
        ),
        fetch(`${URL}movie/upcoming?${params}&language=en-UK&page=1`),
        fetch(`${URL}movie/upcoming?${params}&language=en-UK&page=2`),
        fetch(`${URL}movie/upcoming?${params}&language=en-UK&page=3`),
      ]);

      const [
        popularMovieData,
        popularTvData,
        upcomingMovieData,
        upcomingMovieData2,
        upcomingMovieData3,
      ] = await Promise.all([
        popularMovieResponse.json(),
        popularTvResponse.json(),
        upcomingMoviesAll.json(),
        upcomingMoviesAll2.json(),
        upcomingMoviesAll3.json(),
      ]);

      // Process and dispatch new data
      dispatch({
        type: "POPULAR_SHOWS",
        payload: popularTvData.results,
      });
      dispatch({
        type: "POPULAR_MOVIES",
        payload: popularMovieData.results,
      });

      // Filter the upcoming movie data
      const currentDate = new Date();
      const allUpcomings = upcomingMovieData.results.concat(
        upcomingMovieData2.results,
        upcomingMovieData3.results
      );

      const upcomingMovies = allUpcomings.filter((movie) => {
        const releaseDate = new Date(movie.release_date);
        return releaseDate > currentDate;
      });

      dispatch({
        type: "GET_UPCOMING_MOVIES",
        payload: upcomingMovies,
        upcomingSeries: upcomingSeries,
      });

      // Store the new data with a timestamp in local storage
      localStorage.setItem(
        "popularAndUpcomingData",
        JSON.stringify({
          timestamp: now,
          popularMovies: popularMovieData.results,
          popularShows: popularTvData.results,
          upcomingMovies: upcomingMovies,
        })
      );
    } else {
      // Use the stored data if it's not stale
      dispatch({
        type: "POPULAR_SHOWS",
        payload: data.popularShows,
      });
      dispatch({
        type: "POPULAR_MOVIES",
        payload: data.popularMovies,
      });
      dispatch({
        type: "GET_UPCOMING_MOVIES",
        payload: data.upcomingMovies,
      });
    }
  };

  // get the top movies and shows
  // get the top movies and shows
  const getTop = async () => {
    // Check for top movies and shows in local storage first
    const storedTopMovies = localStorage.getItem("topMovies");
    const storedTopShows = localStorage.getItem("topShows");

    if (storedTopMovies && storedTopShows) {
      // Parse the stored strings back into objects
      const topMovies = JSON.parse(storedTopMovies);
      const topShows = JSON.parse(storedTopShows);

      // Dispatch the stored data instead of fetching new data
      dispatch({
        type: "TOP_MOVIES",
        payload: topMovies,
      });
      dispatch({
        type: "TOP_SHOWS",
        payload: topShows,
      });
    } else {
      // Data not in local storage, so fetch from the API
      const [movieResponse, tvResponse] = await Promise.all([
        fetch(`${URL}movie/top_rated?${params}${lang}`),
        fetch(`${URL}tv/top_rated?${params}${lang}`),
      ]);

      const [movieData, tvData] = await Promise.all([
        movieResponse.json(),
        tvResponse.json(),
      ]);

      // Dispatch fetched data
      dispatch({
        type: "TOP_MOVIES",
        payload: movieData.results,
      });
      dispatch({
        type: "TOP_SHOWS",
        payload: tvData.results,
      });

      // Store the fetched data in local storage for future use
      localStorage.setItem("topMovies", JSON.stringify(movieData.results));
      localStorage.setItem("topShows", JSON.stringify(tvData.results));
    }
  };

  // get bestLast20 movies

  const getBestLast20Years = async () => {
    const monthInMillis = 30 * 24 * 60 * 60 * 1000; // milliseconds in a month
    const now = new Date().getTime();

    const storedBestLast20 = localStorage.getItem("bestLast20Movies");
    let bestLast20Data = storedBestLast20 ? JSON.parse(storedBestLast20) : null;

    const isDataStale =
      !bestLast20Data || now - bestLast20Data.timestamp > monthInMillis;

    if (isDataStale) {
      // Data is stale or not present, fetch new data
      const currentYear = new Date().getFullYear();
      const bestLast20Response = await fetch(
        `${URL}discover/movie?${params}&language=en-US&sort_by=vote_average.desc&include_adult=false&include_video=false&page=1&primary_release_date.gte=${
          currentYear - 20
        }&primary_release_date.lte=${currentYear}&vote_count.gte=3000&vote_count.lte=100000&with_original_language=en`
      );
      const freshBestLast20Data = await bestLast20Response.json();

      // Dispatch new data to update the state
      dispatch({
        type: "GETBESTLAST20_MOVIES",
        payload: freshBestLast20Data.results,
      });

      // Store the new data with a timestamp in local storage
      localStorage.setItem(
        "bestLast20Movies",
        JSON.stringify({ data: freshBestLast20Data.results, timestamp: now })
      );
    } else {
      // Use the stored data if it's not stale
      dispatch({
        type: "GETBESTLAST20_MOVIES",
        payload: bestLast20Data.data,
      });
    }
  };
  // get Upcoming movies and filter the dates to the future relases

  // get best horror

  const getBest = async (genre, noGenre) => {
    const bestResponse = await fetch(
      `${URL}discover/movie?${params}&language=en-US&sort_by=vote_average.desc&include_adult=false&include_video=false&page=1&vote_count.gte=3000&vote_count.lte=1000000&vote_average.gte=7&vote_average.lte=10&with_genres=${
        genre === undefined ? "27" : genre
      }&without_genres=${
        noGenre === undefined ? "16" : noGenre
      }&with_watch_monetization_types=flatrate`
    );
    const bestData = await bestResponse.json();

    dispatch({
      type: "GETBEST_MOVIES",
      payload: bestData.results,
    });
    return bestData.results;
  };

  // get the latest movie
  const getLatestMovies = async () => {
    const latestMovies = await fetch(
      `${URL}movie/now_playing?${params}${lang}`
    );
    const resultLatestMovies = await latestMovies.json();
    dispatch({
      type: "GET_LATEST_MOVIES",
      payload: resultLatestMovies.results,
    });
  };

  const getTrending = async () => {
    const dayInMillis = 24 * 60 * 60 * 1000; // milliseconds in a day
    const now = new Date().getTime();

    // Attempt to retrieve stored trending data
    const storedTrendingData = localStorage.getItem("trendingData");
    let trendingData = storedTrendingData
      ? JSON.parse(storedTrendingData)
      : null;

    // Check if the stored data is stale or not present
    const isDataStale =
      !trendingData || now - trendingData.timestamp > dayInMillis;

    if (isDataStale) {
      // Fetch new trending data if it's stale or not present
      const [trendingMovies, trendingShows, trendingPeople] = await Promise.all(
        [
          fetch(`${URL}trending/movie/day?${params}`).then((response) =>
            response.json()
          ),
          fetch(`${URL}trending/tv/day?${params}`).then((response) =>
            response.json()
          ),
          fetch(`${URL}trending/person/day?${params}`).then((response) =>
            response.json()
          ),
        ]
      );
      console.log(trendingPeople);
      // Apply any filters you need on the fetched data

      const filteredPeople = trendingPeople.results.filter((person) => {
        const europeanRegex = /^\p{L}+$/u;
        const firstStep = !europeanRegex.test(person.original_name);
        console.log(person.id.length);
        return person.id.toString().length < 5 && firstStep;
      });
      console.log(filteredPeople);
      // Update the state with fresh data
      dispatch({
        type: "GET_TRENDING",
        movies: trendingMovies.results,
        shows: trendingShows.results,
        people: filteredPeople,
      });

      // Store the new data with a timestamp in local storage
      localStorage.setItem(
        "trendingData",
        JSON.stringify({
          timestamp: now,
          movies: trendingMovies.results,
          shows: trendingShows.results,
          people: filteredPeople,
        })
      );
    } else {
      // Use the stored data if it's not stale
      dispatch({
        type: "GET_TRENDING",
        ...trendingData, // Spread stored data (movies, shows, and people)
      });
    }
  };

  useEffect(() => {
    getTrending();
    getPopularAndUpcoming();
    getBestLast20Years();
    getBest();
    getTop();
    getLatestMovies();
  }, []);

  return (
    <FirstLoaderContext.Provider
      value={{
        getBest,
        getTop,
        videos: state.videos,
        latestMovies: state.latestMovies,
        series: state.series,
        movies: state.movies,
        trendingMovies: state.trendingMovies,
        trendingShows: state.trendingShows,
        trendingPeople: state.trendingPeople,
        topSeries: state.topSeries,
        topMovies: state.topMovies,
        bestLast20: state.bestLast20,
        best: state.best,
        upcoming: state.upcoming,
        upcomingSeries: state.upcomingSeries,
      }}
    >
      {children}
    </FirstLoaderContext.Provider>
  );
};

export default FirstLoaderContext;
